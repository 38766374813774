import axios from 'axios'
import config from '../config'

export function getWidgetSettings() {
  return axios.get(`${config.API_URL}/iapi/widget-settings`)
}

export function upsertWidgetSettings(body) {
  return axios.post(`${config.API_URL}/iapi/widget-settings`, body)
}
