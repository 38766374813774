import FullPageError from 'components/FullPageError'
import { Link } from 'react-router-dom'

function NotFound() {
  return (
    <FullPageError title='404 - Page not found'>
      <Link to='/'>Go to homepage</Link>
    </FullPageError>
  )
}

export default NotFound
