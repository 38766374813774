import { useMutation } from '@tanstack/react-query'
import { siteChecker } from 'api/misc'
import Alert from 'components/Alert'
import Button from 'components/Button'
import Form from 'components/Form'
import { useState } from 'react'
import styles from './site-checker.module.scss'

function SiteChecker() {
  const [websiteUrl, setWebsiteUrl] = useState('')
  const [submitting, setSubmitting] = useState(false)
  const [error, setError] = useState('')
  const [success, setSuccess] = useState(false)

  const siteCheckerRequest = useMutation(
    data => siteChecker({ website_url: data.websiteUrl }),
    {
      onError: e => {
        setError(e.response.data.message)
        setSubmitting(false)
      },
      onSuccess: () => {
        setSuccess(true)
        setSubmitting(false)
      },
    }
  )

  const handleSubmit = e => {
    e.preventDefault()

    if (websiteUrl.length === 0) {
      return setError('Website URL is required')
    }

    setSubmitting(true)
    setError('')
    siteCheckerRequest.mutate({ websiteUrl })
  }

  return (
    <Form className={styles.form} onSubmit={handleSubmit}>
      <h1 className='mb-4'>Site Checker</h1>

      <Form.Group className='mb-3'>
        <Form.Label>Website URL</Form.Label>
        <Form.Control
          disabled={submitting}
          placeholder='Enter website URL'
          value={websiteUrl}
          onChange={e => {
            setSuccess(false)
            setError('')
            setWebsiteUrl(e.target.value)
          }}
        />
      </Form.Group>

      <Button className='mt-2 mb-4' variant='primary' type='submit' loading={submitting}>
        Check URL
      </Button>

      {success && <Alert variant='success'>This website is compatible with Engage</Alert>}

      {error.length > 0 && <Alert variant='danger'>{error}</Alert>}
    </Form>
  )
}

export default SiteChecker
