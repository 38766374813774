import Button from 'components/Button'
import { useNavigate } from 'react-router-dom'

function GettingStarted() {
  const navigate = useNavigate()

  return (
    <div className='text-center'>
      <h3 className='text-center mb-4'>Getting Started</h3>
      <p>Engage Widget will add iPECS calling and chat to your WordPress Website.</p>

      <Button className='mt-5' onClick={() => navigate('configure')}>
        Get Started
      </Button>
    </div>
  )
}

export default GettingStarted
