import { useMutation } from '@tanstack/react-query'
import { initCSRF, login } from 'api/auth'
import Alert from 'components/Alert'
import Button from 'components/Button'
import Form from 'components/Form'
import { useAuth } from 'contexts/AuthContext'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import styles from './login.module.scss'

function Login() {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [errors, setErrors] = useState()
  const [submitting, setSubmitting] = useState(false)
  const { setMe } = useAuth()

  const loginRequest = useMutation(data => login(data.email, data.password), {
    onError: async e => {
      if (e.response && e.response.status === 419) {
        await initCSRF()
        return loginRequest.mutate({ email, password })
      }
      setErrors(e.response.data)
      setSubmitting(false)
    },
    onSuccess: () => {
      setMe()
    },
  })

  const handleSubmit = e => {
    e.preventDefault()

    if (email.length === 0 || password.length === 0) {
      return setErrors({ message: 'Email and password are required' })
    }

    setSubmitting(true)
    setErrors()
    loginRequest.mutate({ email, password })
  }

  return (
    <Form className={styles.form} onSubmit={handleSubmit}>
      <div className='mb-5 px-5'>
        <img src='/logo.svg' alt='Logo' />
      </div>

      <Form.Group className='mb-3'>
        <Form.Label>Email address</Form.Label>
        <Form.Control
          disabled={submitting}
          type='email'
          placeholder='Enter email'
          value={email}
          onChange={e => setEmail(e.target.value)}
        />
      </Form.Group>

      <Form.Group className='mb-3'>
        <Form.Label>Password</Form.Label>
        <Form.Control
          disabled={submitting}
          type='password'
          placeholder='Password'
          value={password}
          onChange={e => setPassword(e.target.value)}
        />
      </Form.Group>

      <Button className='mt-3 mb-3' variant='primary' type='submit' loading={submitting}>
        Login
      </Button>

      <div className='mb-4'>
        <Link to='/forgot-password'>Forgotten password?</Link>
      </div>

      {errors?.message && <Alert variant='danger'>{errors.message}</Alert>}
    </Form>
  )
}

export default Login
