import { useMutation } from '@tanstack/react-query'
import { forgotPassword } from 'api/auth'
import Alert from 'components/Alert'
import Button from 'components/Button'
import Form from 'components/Form'
import { useState } from 'react'
import styles from './forgot-password.module.scss'
import { Link } from 'react-router-dom'

function ForgotPassword() {
  const [email, setEmail] = useState('')
  const [errors, setErrors] = useState()
  const [submitting, setSubmitting] = useState(false)
  const [submitted, setSubmitted] = useState(false)

  const forgotPasswordRequest = useMutation(data => forgotPassword(email), {
    onError: e => {
      setErrors(e.response.data)
      setSubmitting(false)
    },
    onSuccess: () => {
      setSubmitted(true)
    },
  })

  const handleSubmit = e => {
    e.preventDefault()

    if (email.length === 0) {
      return setErrors({ message: 'Email is required' })
    }

    setSubmitting(true)
    setErrors()
    forgotPasswordRequest.mutate({ email })
  }

  return (
    <Form className={styles.form} onSubmit={handleSubmit}>
      <div className='mb-5 px-5'>
        <img src='/logo.svg' alt='Logo' />
      </div>

      <h4 className='mb-4'>Forgot Password</h4>

      {submitted ? (
        <>
          <p>
            We have emailed you instructions on how to reset your password, it may take a
            few minutes to reach your inbox.
          </p>

          <Link to='/'>Back to login</Link>
        </>
      ) : (
        <>
          <p>Please enter your email below to continue.</p>

          <Form.Group className='mb-3'>
            <Form.Control
              disabled={submitting}
              type='email'
              placeholder='Enter email'
              value={email}
              onChange={e => setEmail(e.target.value)}
            />
          </Form.Group>

          <Button
            className='mt-3 mb-3'
            variant='primary'
            type='submit'
            loading={submitting}>
            Submit
          </Button>

          <div className='mb-4'>
            <Link to='/'>Back to login</Link>
          </div>

          {errors?.message && <Alert variant='danger'>{errors.message}</Alert>}
        </>
      )}
    </Form>
  )
}

export default ForgotPassword
