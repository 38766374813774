import Button from 'components/Button'
import { useEffect, useState } from 'react'
import { SketchPicker } from 'react-color'
import styles from './SketchColorPicker.module.scss'

function SketchColorPicker({ hex, ...otherProps }) {
  const [show, setShow] = useState(false)
  const [textColor, setTextColor] = useState('#000')

  useEffect(() => {
    if (hex.length > 0) {
      const fullHex =
        hex.length === 4
          ? hex
              .split('')
              .map(item => (item === '#' ? item : item + item))
              .join('')
          : hex

      const r = parseInt(fullHex.substring(1, 3), 16)
      const g = parseInt(fullHex.substring(3, 5), 16)
      const b = parseInt(fullHex.substring(5, 7), 16)

      if (r !== undefined && b !== undefined && g !== undefined) {
        setTextColor(r * 0.299 + g * 0.587 + b * 0.114 > 186 ? '#000' : '#fff')
      }
    }
  }, [hex])

  return (
    <div>
      <Button
        style={{ backgroundColor: hex, color: textColor }}
        variant='light'
        className='text-start w-100'
        onClick={() => setShow(true)}>
        {hex}
      </Button>
      {show && (
        <>
          <div onClick={() => setShow(false)} className={styles.overlay}></div>
          <div className={styles.picker}>
            <SketchPicker
              {...otherProps}
              color={hex}
              presetColors={[]}
              disableAlpha={true}
            />
          </div>
        </>
      )}
    </div>
  )
}

export default SketchColorPicker
