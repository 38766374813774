import { useState } from 'react'
import { BsChatRightText } from 'react-icons/bs'

function WidgetPreview({
  textColor,
  bgColor,
  highlightTextColor,
  highlightBgColor,
  shadowColor,
  size,
  placement,
  xOffset,
  yOffset,
  borderRadius,
}) {
  const [hover, setHover] = useState(false)

  return (
    <div
      style={{
        position: 'fixed',
        ...{ ...style[placement] },
        margin: `${yOffset}px ${xOffset}px`,
      }}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          boxShadow: `1px 3px 4px 2px ${shadowColor}`,
          backgroundColor: hover ? highlightBgColor : bgColor,
          color: hover ? highlightTextColor : textColor,
          height: `${size}px`,
          width: `${size}px`,
          transition: 'all 200ms ease-in-out',
          scale: hover ? '1.2' : '1',
          cursor: 'pointer',
          borderRadius: `${borderRadius}px`,
        }}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}>
        <BsChatRightText style={style.icon} />
      </div>
    </div>
  )
}

const style = {
  container: {
    zIndex: 9999,
    position: 'fixed',
  },
  bottomLeft: {
    bottom: 0,
    left: 0,
  },
  bottomRight: {
    bottom: 0,
    right: 0,
  },
  icon: {
    height: '50%',
    width: '50%',
  },
}

export default WidgetPreview
