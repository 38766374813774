import { useMutation } from '@tanstack/react-query'
import { resetPassword } from 'api/auth'
import Alert from 'components/Alert'
import Button from 'components/Button'
import Form from 'components/Form'
import { useState } from 'react'
import styles from './reset-password.module.scss'
import { Link, useParams } from 'react-router-dom'

function ResetPassword() {
  const { token, email } = useParams()
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [errors, setErrors] = useState()
  const [submitting, setSubmitting] = useState(false)
  const [submitted, setSubmitted] = useState(false)

  const resetPasswordRequest = useMutation(
    data => resetPassword(token, email, password),
    {
      onError: e => {
        setErrors(e.response.data)
        setSubmitting(false)
      },
      onSuccess: () => {
        setSubmitted(true)
      },
    }
  )

  const handleSubmit = e => {
    e.preventDefault()

    if (password.length === 0 || confirmPassword.length === 0) {
      return setErrors({ message: 'Password is required' })
    }

    if (password !== confirmPassword) {
      return setErrors({ message: 'Passwords do not match' })
    }

    setSubmitting(true)
    setErrors()
    resetPasswordRequest.mutate({ password })
  }

  return (
    <Form className={styles.form} onSubmit={handleSubmit}>
      <div className='mb-5 px-5'>
        <img src='/logo.svg' alt='Logo' />
      </div>

      <h4 className='mb-4'>Reset Password</h4>

      {submitted ? (
        <>
          <p>Your password has been reset.</p>

          <Link to='/'>Back to login</Link>
        </>
      ) : (
        <>
          <Form.Group className='mb-3'>
            <Form.Label>New Password</Form.Label>
            <Form.Control
              disabled={submitting}
              type='password'
              placeholder='Enter new password'
              value={password}
              onChange={e => setPassword(e.target.value)}
            />
          </Form.Group>

          <Form.Group className='mb-3'>
            <Form.Label>Re-enter new password</Form.Label>
            <Form.Control
              disabled={submitting}
              type='password'
              placeholder='Enter new password'
              value={confirmPassword}
              onChange={e => setConfirmPassword(e.target.value)}
            />
          </Form.Group>

          <Button
            className='mt-3 mb-3'
            variant='primary'
            type='submit'
            loading={submitting}>
            Submit
          </Button>

          {errors?.message && <Alert variant='danger'>{errors.message}</Alert>}
        </>
      )}
    </Form>
  )
}

export default ResetPassword
