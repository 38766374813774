import error from 'animations/error'
import PropTypes from 'prop-types'
import Lottie from 'react-lottie'

function FullPageError({ title, children }) {
  return (
    <div className='h-75 d-flex align-items-center justify-content-center flex-column'>
      <h3>{title}</h3>
      {children}
      <Lottie
        options={{
          loop: true,
          autoplay: true,
          animationData: error,
        }}
        height={400}
      />
    </div>
  )
}

FullPageError.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
}

FullPageError.defaultProps = {
  children: <p>Sorry about this, looks like we've run into an issue.</p>,
}

export default FullPageError
