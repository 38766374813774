import loader from 'animations/loader'
import Lottie from 'react-lottie'

function FullPageLoader({ children, ...otherProps }) {
  return (
    <div className='h-75 d-flex align-items-center justify-content-center'>
      <Lottie
        options={{
          loop: true,
          autoplay: true,
          animationData: loader,
        }}
        {...otherProps}
      />
    </div>
  )
}

export default FullPageLoader
