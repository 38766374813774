import BsForm from 'react-bootstrap/Form'

function Form({ children, ...otherProps }) {
  return <BsForm {...otherProps}>{children}</BsForm>
}

function FieldError({ children }) {
  return <BsForm.Text className='text-danger'>{children}</BsForm.Text>
}

export default Object.assign(Form, {
  Group: BsForm.Group,
  Control: BsForm.Control,
  Label: BsForm.Label,
  Check: BsForm.Check,
  Text: BsForm.Text,
  FieldError: FieldError,
})
