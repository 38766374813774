import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import axios from 'axios'
import { useEffect } from 'react'
import { initCSRF } from './api/auth'
import './App.scss'
import { AuthProvider } from './contexts/AuthContext'

import Routes from './routes/Init'

axios.defaults.withCredentials = true

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      staleTime: 5000,
    },
  },
})

function App() {
  useEffect(() => initCSRF(), [])

  return (
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <Routes />
      </AuthProvider>
    </QueryClientProvider>
  )
}

export default App
