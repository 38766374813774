import { useQueryClient } from '@tanstack/react-query'
import { logout, me } from 'api/auth'
import FullPageLoader from 'components/FullPageLoader'
import React, { useContext, useEffect, useState } from 'react'

const AuthContext = React.createContext()

const AuthProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(true)
  const [user, setUser] = useState(null)

  const queryClient = useQueryClient()

  useEffect(() => {
    me()
      .then(res => setUser(res.data.data))
      .catch(res => setUser(null))
      .finally(() => setIsLoading(false))
  }, [])

  if (isLoading) return <FullPageLoader />

  const setMe = () => me().then(res => setUser(res.data.data))
  const handleLogout = () => {
    logout()
    setUser(null)
    queryClient.clear()
  }

  const defaultContext = {
    user,
    setMe,
    handleLogout,
  }

  return <AuthContext.Provider value={defaultContext}>{children}</AuthContext.Provider>
}

function useAuth() {
  return useContext(AuthContext)
}

export { AuthProvider, useAuth }
