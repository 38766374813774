import NotFound from 'components/NotFound'
import ForgotPassword from 'containers/ForgotPassword'
import Login from 'containers/Login'
import ResetPassword from 'containers/ResetPassword'
import SiteChecker from 'containers/SiteChecker'
import Widget from 'containers/Widget'
import WidgetConfig from 'containers/WidgetConfig'
import { useAuth } from 'contexts/AuthContext'
import Container from 'react-bootstrap/Container'
import Nav from 'react-bootstrap/Nav'
import Navbar from 'react-bootstrap/Navbar'
import { BrowserRouter, Link, Navigate, Outlet, Route, Routes } from 'react-router-dom'

const Init = () => {
  const { user } = useAuth()

  const unauthenticatedRoutes = () => {
    return (
      <>
        <Route path='/' element={<UnauthLayout />}>
          <Route index element={<Login />} />
          <Route path='/forgot-password' element={<ForgotPassword />} />
          <Route path='/reset-password/:token/:email' element={<ResetPassword />} />
          <Route path='/site-checker' element={<SiteChecker />} />

          <Route path='/*' element={<Navigate to='/' />} />
        </Route>
      </>
    )
  }

  const authenticatedRoutes = type => {
    switch (type) {
      case 'CUSTOMER':
        return (
          <Route path='/' element={<AuthLayout />}>
            <Route index element={<Widget />} />
            <Route path='/configure' element={<WidgetConfig />} />
          </Route>
        )
      default:
        return (
          <Route path='/*' element={<AuthLayout />}>
            <Route
              index
              element={<div>You can not access this site with your role.</div>}
            />
          </Route>
        )
    }
  }

  return (
    <BrowserRouter>
      <Routes>
        {user ? authenticatedRoutes(user.type) : unauthenticatedRoutes()}
        <Route path='/*' element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  )
}

function UnauthLayout() {
  return (
    <div className='h-75 d-flex align-items-center justify-content-center'>
      <Outlet />
    </div>
  )
}

function AuthLayout() {
  const { handleLogout } = useAuth()

  return (
    <div>
      <Navbar bg='dark' variant='dark' expand='lg' sticky='top'>
        <Container>
          <Navbar.Brand as={Link} to='/'>
            <img style={{ height: '40px' }} src='/logo.svg' alt='Engage' />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls='basic-navbar-nav' />
          <Navbar.Collapse id='basic-navbar-nav'>
            <Nav className='ms-auto'>
              <Nav.Link onClick={handleLogout}>Logout</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <div
        className='pt-4 h-75 d-flex align-items-center justify-content-center'
        style={{ paddingBottom: '300px' }}>
        <Outlet />
      </div>
    </div>
  )
}

export default Init
