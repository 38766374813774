import Alert from 'components/Alert'
import Button from 'components/Button'
import { useAuth } from 'contexts/AuthContext'
import { CopyBlock, dracula } from 'react-code-blocks'
import { useNavigate } from 'react-router-dom'
import styles from './usage.module.scss'

const SCRIPT_URL = 'https://webwidget.engage.cosoft.co.uk/cosoft-engage.js'
const WP_PLUGIN_DOWNLOAD =
  'https://storage.googleapis.com/ipecs-engage-web-widget-public/cosoft-engage-wordpress-plugin-v1.0.0.zip'

function Usage() {
  const navigate = useNavigate()

  const { user } = useAuth()

  return (
    <div className={styles.container}>
      <div>
        {user.website_processed ? (
          <Alert variant='success' className='text-center'>
            Your widget is ready to use with full AI integration.
          </Alert>
        ) : (
          <Alert variant='info' className='text-center'>
            We are processing your website data, you can use your widget without AI
            integration, this wil be ready soon.
          </Alert>
        )}
      </div>

      <h1>Usage</h1>

      <div className='mb-4'>
        <strong>WordPress Plugin</strong>
        <p>
          Download the Engage WordPress Plugin{' '}
          <a href={WP_PLUGIN_DOWNLOAD} target='_blank' rel='noreferrer'>
            from here
          </a>{' '}
          and install within your WordPress site.
        </p>
        <p>
          Once installed you should see "Engage" under your settings menu, where it says
          Engage Script URL enter...
        </p>
        <p>
          <em>
            {SCRIPT_URL}?r={user.widget_id}
          </em>
        </p>
      </div>

      <div className='mb-4'>
        <strong>Custom Install</strong>
        <p>Add the below code snippet to your website.</p>
        <CopyBlock
          text={`<div id="cosoft_engage_container" data-ie-id="${user.widget_id}"></div>
<script type="module" src="${SCRIPT_URL}"></script>`}
          language='html'
          theme={dracula}
        />
      </div>

      <div className='text-center'>
        <Button className='mt-5' onClick={() => navigate('configure')}>
          Edit Widget Config
        </Button>
      </div>
    </div>
  )
}

export default Usage
