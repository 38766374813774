import axios from 'axios'
import config from '../config'

export function initCSRF() {
  return axios.get(`${config.API_URL}/iapi/csrf-cookie`)
}

export function login(email, password) {
  return axios.post(`${config.API_URL}/iapi/login`, {
    email: email,
    password: password,
  })
}

export function logout() {
  return axios.post(`${config.API_URL}/iapi/logout`)
}

export function forgotPassword(email) {
  return axios.post(`${config.API_URL}/iapi/forgot-password`, {
    email: email,
  })
}

export function resetPassword(token, email, password) {
  return axios.post(`${config.API_URL}/iapi/reset-password`, {
    token: token,
    email: email,
    password: password,
    password_confirmation: password,
  })
}

export function me() {
  return axios.get(`${config.API_URL}/iapi/me`)
}
