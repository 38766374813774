import { useMutation, useQuery } from '@tanstack/react-query'
import { getWidgetSettings, upsertWidgetSettings } from 'api/widget-settings'
import Alert from 'components/Alert'
import Button from 'components/Button'
import Form from 'components/Form'
import FullPageError from 'components/FullPageError'
import FullPageLoader from 'components/FullPageLoader'
import SketchColorPicker from 'components/SketchColorPicker'
import Slider from 'components/Slider'
import WidgetPreview from 'components/WidgetPreview'
import { useAuth } from 'contexts/AuthContext'
import queryKeys from 'query-keys'
import { useEffect, useState } from 'react'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import { useNavigate } from 'react-router-dom'
import styles from './widgetconfig.module.scss'

function WidgetConfig() {
  const { setMe } = useAuth()
  const navigate = useNavigate()

  const { isFetching, error, data } = useQuery(
    [queryKeys.GET_WIDGET_SETTINGS],
    () => {
      return getWidgetSettings()
    },
    {
      keepPreviousData: true,
    }
  )

  const upsertMutation = useMutation(
    data => {
      return upsertWidgetSettings(data)
    },
    {
      onError: error => {
        setSubmitting(false)
        setSubmitError(error.response.data)
      },
      onSuccess: () => {
        setMe().then(() => navigate('/'))
      },
    }
  )

  const [showPreview, setShowPreview] = useState(true)
  const [submitting, setSubmitting] = useState(false)
  const [submitError, setSubmitError] = useState()
  const [ipecsCallDestination, setIpecsCallDestination] = useState('')
  const [ipecsVideoDestination, setIpecsVideoDestination] = useState('')
  const [ipecsChatDestination, setIpecsChatDestination] = useState('')

  const [ohMonStart, setOhMonStart] = useState('')
  const [ohMonEnd, setOhMonEnd] = useState('')
  const [ohTueStart, setOhTueStart] = useState('')
  const [ohTueEnd, setOhTueEnd] = useState('')
  const [ohWedStart, setOhWedStart] = useState('')
  const [ohWedEnd, setOhWedEnd] = useState('')
  const [ohThuStart, setOhThuStart] = useState('')
  const [ohThuEnd, setOhThuEnd] = useState('')
  const [ohFriStart, setOhFriStart] = useState('')
  const [ohFriEnd, setOhFriEnd] = useState('')
  const [ohSatStart, setOhSatStart] = useState('')
  const [ohSatEnd, setOhSatEnd] = useState('')
  const [ohSunStart, setOhSunStart] = useState('')
  const [ohSunEnd, setOhSunEnd] = useState('')

  const [appearanceTextColor, setAppearanceTextColor] = useState('')
  const [appearanceBgColor, setAppearanceBgColor] = useState('')
  const [appearanceHighlightTextColor, setAppearanceHighlightTextColor] = useState('')
  const [appearanceHighlightBgColor, setAppearanceHighlightBgColor] = useState('')
  const [appearanceShadowColor, setAppearanceShadowColor] = useState('')
  const [appearanceSize, setAppearanceSize] = useState('')
  const [appearancePlacement, setAppearancePlacement] = useState('')
  const [appearanceXOffset, setAppearanceXOffset] = useState('')
  const [appearanceYOffset, setAppearanceYOffset] = useState('')
  const [appearanceBorderRadius, setAppearanceBorderRadius] = useState('')

  useEffect(() => {
    if (data) {
      setIpecsCallDestination(data.data.data.ipecs.call_destination)
      setIpecsVideoDestination(data.data.data.ipecs.video_destination)
      setIpecsChatDestination(data.data.data.ipecs.chat_destination)

      setOhMonStart(data.data.data.office_hours.mon_start)
      setOhMonEnd(data.data.data.office_hours.mon_end)
      setOhTueStart(data.data.data.office_hours.tue_start)
      setOhTueEnd(data.data.data.office_hours.tue_end)
      setOhWedStart(data.data.data.office_hours.wed_start)
      setOhWedEnd(data.data.data.office_hours.wed_end)
      setOhThuStart(data.data.data.office_hours.thu_start)
      setOhThuEnd(data.data.data.office_hours.thu_end)
      setOhFriStart(data.data.data.office_hours.fri_start)
      setOhFriEnd(data.data.data.office_hours.fri_end)
      setOhSatStart(data.data.data.office_hours.sat_start)
      setOhSatEnd(data.data.data.office_hours.sat_end)
      setOhSunStart(data.data.data.office_hours.sun_start)
      setOhSunEnd(data.data.data.office_hours.sun_end)

      setAppearanceTextColor(data.data.data.appearance.text_color)
      setAppearanceBgColor(data.data.data.appearance.bg_color)
      setAppearanceHighlightTextColor(data.data.data.appearance.highlight_text_color)
      setAppearanceHighlightBgColor(data.data.data.appearance.highlight_bg_color)
      setAppearanceShadowColor(data.data.data.appearance.shadow_color)
      setAppearanceSize(data.data.data.appearance.size)
      setAppearancePlacement(data.data.data.appearance.placement)
      setAppearanceXOffset(data.data.data.appearance.x_offset)
      setAppearanceYOffset(data.data.data.appearance.y_offset)
      setAppearanceBorderRadius(data.data.data.appearance.border_radius)
    }
  }, [data])

  const handleChangeFormField = (setter, value) => {
    setSubmitError()
    setter(value)
  }

  const handleSubmit = e => {
    e.preventDefault()
    setSubmitting(true)
    upsertMutation.mutate({
      ipecs_call_destination: ipecsCallDestination,
      ipecs_video_destination: ipecsVideoDestination,
      ipecs_chat_destination: ipecsChatDestination,
      oh_mon_start: ohMonStart,
      oh_mon_end: ohMonEnd,
      oh_tue_start: ohTueStart,
      oh_tue_end: ohTueEnd,
      oh_wed_start: ohWedStart,
      oh_wed_end: ohWedEnd,
      oh_thu_start: ohThuStart,
      oh_thu_end: ohThuEnd,
      oh_fri_start: ohFriStart,
      oh_fri_end: ohFriEnd,
      oh_sat_start: ohSatStart,
      oh_sat_end: ohSatEnd,
      oh_sun_start: ohSunStart,
      oh_sun_end: ohSunEnd,
      appearance_text_color: appearanceTextColor,
      appearance_bg_color: appearanceBgColor,
      appearance_highlight_text_color: appearanceHighlightTextColor,
      appearance_highlight_bg_color: appearanceHighlightBgColor,
      appearance_shadow_color: appearanceShadowColor,
      appearance_size: appearanceSize,
      appearance_placement: appearancePlacement,
      appearance_x_offset: appearanceXOffset,
      appearance_y_offset: appearanceYOffset,
      appearance_border_radius: appearanceBorderRadius,
    })
  }

  if (isFetching || submitting) return <FullPageLoader height={200} />
  if (error) return <FullPageError />

  return (
    <Container>
      <Form className={styles.form} onSubmit={handleSubmit}>
        <Row>
          <Col xs={12} md={6} xl={6}>
            <h4>iPECS Config</h4>
            <p>
              Enter your iPECS configuration details to enable audio, video and agent chat
              messaging.
            </p>

            <Form.Group className='mb-3'>
              <Form.Label>Audio Call Destination</Form.Label>
              <Form.Control
                disabled={submitting}
                placeholder='200'
                value={ipecsCallDestination}
                onChange={e =>
                  handleChangeFormField(setIpecsCallDestination, e.target.value)
                }
              />
              <Form.Text className='ms-2 text-muted'>
                Leave blank if you would not like audio calling enabled
              </Form.Text>
              {submitError?.errors.ipecs_call_destination && (
                <Form.FieldError>
                  {submitError?.errors.ipecs_call_destination}
                </Form.FieldError>
              )}
            </Form.Group>

            <Form.Group className='mb-3'>
              <Form.Label>Video Call Destination</Form.Label>
              <Form.Control
                disabled={submitting}
                placeholder='200'
                value={ipecsVideoDestination}
                onChange={e =>
                  handleChangeFormField(setIpecsVideoDestination, e.target.value)
                }
              />
              <Form.Text className='ms-2 text-muted'>
                Leave blank if you would not like video calling enabled
              </Form.Text>
              {submitError?.errors.ipecs_video_destination && (
                <Form.FieldError>
                  {submitError?.errors.ipecs_video_destination}
                </Form.FieldError>
              )}
            </Form.Group>

            <Form.Group className='mb-3'>
              <Form.Label>Chat Destination</Form.Label>
              <Form.Control
                disabled={submitting}
                placeholder='103'
                value={ipecsChatDestination}
                onChange={e =>
                  handleChangeFormField(setIpecsChatDestination, e.target.value)
                }
              />
              {submitError?.errors.ipecs_chat_destination && (
                <Form.FieldError>
                  {submitError?.errors.ipecs_chat_destination}
                </Form.FieldError>
              )}
            </Form.Group>
          </Col>

          <Col xs={12} md={6} xl={6}>
            <h4 className='me-2'>Office Hours</h4>
            <p>
              Audio, video and agent chat messages will only be available to users during
              these hours. Leave blank if closed on that day.
            </p>

            <OfficeHourDay
              dayLabel='Monday'
              startValue={ohMonStart}
              endValue={ohMonEnd}
              onChangeStart={e => handleChangeFormField(setOhMonStart, e.target.value)}
              onChangeEnd={e => handleChangeFormField(setOhMonEnd, e.target.value)}
              startError={submitError?.errors.oh_mon_start}
              endError={submitError?.errors.oh_mon_end}
            />

            <OfficeHourDay
              dayLabel='Tuesday'
              startValue={ohTueStart}
              endValue={ohTueEnd}
              onChangeStart={e => handleChangeFormField(setOhTueStart, e.target.value)}
              onChangeEnd={e => handleChangeFormField(setOhTueEnd, e.target.value)}
              startError={submitError?.errors.oh_tue_start}
              endError={submitError?.errors.oh_tue_end}
            />

            <OfficeHourDay
              dayLabel='Wednesday'
              startValue={ohWedStart}
              endValue={ohWedEnd}
              onChangeStart={e => handleChangeFormField(setOhWedStart, e.target.value)}
              onChangeEnd={e => handleChangeFormField(setOhWedEnd, e.target.value)}
              startError={submitError?.errors.oh_wed_start}
              endError={submitError?.errors.oh_wed_end}
            />

            <OfficeHourDay
              dayLabel='Thursday'
              startValue={ohThuStart}
              endValue={ohThuEnd}
              onChangeStart={e => handleChangeFormField(setOhThuStart, e.target.value)}
              onChangeEnd={e => handleChangeFormField(setOhThuEnd, e.target.value)}
              startError={submitError?.errors.oh_thu_start}
              endError={submitError?.errors.oh_thu_end}
            />

            <OfficeHourDay
              dayLabel='Friday'
              startValue={ohFriStart}
              endValue={ohFriEnd}
              onChangeStart={e => handleChangeFormField(setOhFriStart, e.target.value)}
              onChangeEnd={e => handleChangeFormField(setOhFriEnd, e.target.value)}
              startError={submitError?.errors.oh_fri_start}
              endError={submitError?.errors.oh_fri_end}
            />

            <OfficeHourDay
              dayLabel='Saturday'
              startValue={ohSatStart}
              endValue={ohSatEnd}
              onChangeStart={e => handleChangeFormField(setOhSatStart, e.target.value)}
              onChangeEnd={e => handleChangeFormField(setOhSatEnd, e.target.value)}
              startError={submitError?.errors.oh_sat_start}
              endError={submitError?.errors.oh_sat_end}
            />

            <OfficeHourDay
              dayLabel='Sunday'
              startValue={ohSunStart}
              endValue={ohSunEnd}
              onChangeStart={e => handleChangeFormField(setOhSunStart, e.target.value)}
              onChangeEnd={e => handleChangeFormField(setOhSunEnd, e.target.value)}
              startError={submitError?.errors.oh_sun_start}
              endError={submitError?.errors.oh_sun_end}
            />
          </Col>

          <Col xs={12} md={12} xl={12} className='mt-5'>
            <div className='d-flex align-items-center'>
              <h4 className='me-2'>Appearance</h4>

              <Form.Check
                type='switch'
                label='Show Preview'
                checked={showPreview}
                onChange={e => setShowPreview(e.target.checked)}
              />
            </div>

            <Form.Group className='mb-3'>
              <Form.Group className='mb-3'>
                <Form.Label>Placement</Form.Label>
                <Form.Control
                  as='select'
                  value={appearancePlacement}
                  onChange={e => {
                    handleChangeFormField(setAppearancePlacement, e.target.value)
                  }}>
                  <option value='bottomRight'>Bottom Right</option>
                  <option value='bottomLeft'>Bottom Left</option>
                </Form.Control>
                {submitError?.errors.appearance_placement && (
                  <Form.FieldError>
                    {submitError?.errors.appearance_placement}
                  </Form.FieldError>
                )}
              </Form.Group>
              <Form.Label>Text Colour</Form.Label>
              <SketchColorPicker
                hex={appearanceTextColor}
                onChange={color =>
                  handleChangeFormField(setAppearanceTextColor, color.hex)
                }
              />
              {submitError?.errors.appearance_text_color && (
                <Form.FieldError>
                  {submitError?.errors.appearance_text_color}
                </Form.FieldError>
              )}
            </Form.Group>

            <Form.Group className='mb-3'>
              <Form.Label>Background Colour</Form.Label>
              <SketchColorPicker
                hex={appearanceBgColor}
                onChange={color => handleChangeFormField(setAppearanceBgColor, color.hex)}
              />
              {submitError?.errors.appearance_bg_color && (
                <Form.FieldError>
                  {submitError?.errors.appearance_bg_color}
                </Form.FieldError>
              )}
            </Form.Group>

            <Form.Group className='mb-3'>
              <Form.Label>Highlight Text Colour</Form.Label>
              <SketchColorPicker
                hex={appearanceHighlightTextColor}
                onChange={color =>
                  handleChangeFormField(setAppearanceHighlightTextColor, color.hex)
                }
              />
              {submitError?.errors.appearance_highlight_text_color && (
                <Form.FieldError>
                  {submitError?.errors.appearance_highlight_text_color}
                </Form.FieldError>
              )}
            </Form.Group>

            <Form.Group className='mb-3'>
              <Form.Label>Highlight Background Colour</Form.Label>
              <SketchColorPicker
                hex={appearanceHighlightBgColor}
                onChange={color =>
                  handleChangeFormField(setAppearanceHighlightBgColor, color.hex)
                }
              />
              {submitError?.errors.appearance_highlight_bg_color && (
                <Form.FieldError>
                  {submitError?.errors.appearance_highlight_bg_color}
                </Form.FieldError>
              )}
            </Form.Group>

            <Form.Group className='mb-3'>
              <Form.Label>Shadow Colour</Form.Label>
              <SketchColorPicker
                hex={appearanceShadowColor}
                onChange={color =>
                  handleChangeFormField(setAppearanceShadowColor, color.hex)
                }
              />
              {submitError?.errors.appearance_shadow_coloe && (
                <Form.FieldError>
                  {submitError?.errors.appearance_shadow_coloe}
                </Form.FieldError>
              )}
            </Form.Group>

            <Form.Group className='mb-3'>
              <Form.Label>Size</Form.Label>
              <FormSlider
                value={appearanceSize}
                setValue={setAppearanceSize}
                min={30}
                max={120}
              />
              {submitError?.errors.appearance_size && (
                <Form.FieldError>{submitError?.errors.appearance_size}</Form.FieldError>
              )}
            </Form.Group>

            <Form.Group className='mb-3'>
              <Form.Label>X Offset</Form.Label>
              <FormSlider
                value={appearanceXOffset}
                setValue={setAppearanceXOffset}
                min={0}
                max={240}
              />
              {submitError?.errors.appearance_x_offset && (
                <Form.FieldError>
                  {submitError?.errors.appearance_x_offset}
                </Form.FieldError>
              )}
            </Form.Group>

            <Form.Group className='mb-3'>
              <Form.Label>Y Offset</Form.Label>
              <FormSlider
                value={appearanceYOffset}
                setValue={setAppearanceYOffset}
                min={0}
                max={240}
              />
              {submitError?.errors.appearance_y_offset && (
                <Form.FieldError>
                  {submitError?.errors.appearance_y_offset}
                </Form.FieldError>
              )}
            </Form.Group>

            <Form.Group className='mb-3'>
              <Form.Label>Border Radius</Form.Label>
              <FormSlider
                value={appearanceBorderRadius}
                setValue={setAppearanceBorderRadius}
                min={0}
                max={40}
              />
              {submitError?.errors.appearance_border_radius && (
                <Form.FieldError>
                  {submitError?.errors.appearance_border_radius}
                </Form.FieldError>
              )}
            </Form.Group>
          </Col>
        </Row>

        {submitError?.message && (
          <Alert className='mt-4' variant='danger'>
            {submitError.message}
          </Alert>
        )}

        <div className={styles.buttons}>
          <Button
            onClick={() => navigate(-1)}
            className='ms-1 mt-4 mb-4'
            variant='dark'
            disabled={submitting}>
            Cancel
          </Button>

          <Button
            className='mt-4 mb-4'
            variant='primary'
            type='submit'
            loading={submitting}>
            Save
          </Button>
        </div>
      </Form>

      {showPreview && (
        <WidgetPreview
          textColor={appearanceTextColor}
          bgColor={appearanceBgColor}
          highlightTextColor={appearanceHighlightTextColor}
          highlightBgColor={appearanceHighlightBgColor}
          shadowColor={appearanceShadowColor}
          size={appearanceSize}
          placement={appearancePlacement}
          xOffset={appearanceXOffset}
          yOffset={appearanceYOffset}
          borderRadius={appearanceBorderRadius}
        />
      )}
    </Container>
  )
}

function FormSlider({ value, setValue, min, max }) {
  return (
    <div>
      <Slider
        className='w-100'
        axis='x'
        xstep={1}
        xmin={min}
        xmax={max}
        x={value}
        onChange={({ x }) => setValue(parseInt(x))}
      />
    </div>
  )
}

function OfficeHourDay({
  dayLabel,
  startValue,
  endValue,
  onChangeStart,
  onChangeEnd,
  submitting,
  startError,
  endError,
}) {
  return (
    <div>
      <Form.Label>{dayLabel}</Form.Label>
      <div className='d-flex mb-3' style={{ gap: '8px' }}>
        <Form.Group className='flex-grow-1'>
          <Form.Control
            type='time'
            disabled={submitting}
            placeHolder='08:00'
            value={startValue}
            onChange={onChangeStart}
          />
          {startError && <Form.FieldError>{startError}</Form.FieldError>}
        </Form.Group>
        <span className='mt-2'> - </span>
        <Form.Group className='flex-grow-1'>
          <Form.Control
            type='time'
            disabled={submitting}
            placeHolder='08:00'
            value={endValue}
            onChange={onChangeEnd}
          />
          {endError && <Form.FieldError>{endError}</Form.FieldError>}
        </Form.Group>
      </div>
    </div>
  )
}

export default WidgetConfig
