import GettingStarted from 'components/GettingStarted'
import Usage from 'components/Usage'
import { useAuth } from 'contexts/AuthContext'

function Widget() {
  const { user } = useAuth()

  return !user.widget_configured ? <GettingStarted /> : <Usage />
}

export default Widget
