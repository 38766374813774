import { Spinner } from 'react-bootstrap'
import BsButton from 'react-bootstrap/Button'
import PropTypes from 'prop-types'
import styles from './button.module.scss'

function Button({ loading, disabled, style, children, ...otherProps }) {
  return (
    <BsButton
      style={{ ...style, position: 'relative' }}
      disabled={disabled || loading}
      {...otherProps}>
      {loading && <Spinner className={styles.loader} animation='border' size='sm' />}
      <span style={{ visibility: loading ? 'hidden' : 'visible' }}>{children}</span>
    </BsButton>
  )
}

Button.propTypes = {
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  style: PropTypes.object,
}

Button.defaultProps = {
  loading: false,
  disabled: false,
  style: {},
}

export default Button
